import * as React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Breadcrumb from '../components/breadcrumbs'

class BlogPage extends React.Component {
    render() {
        const posts = this.props.data.allContentfulBlog.edges
        let title = "Blog 🗒 | Pyxel Development"
        let description = "Pyxel Development provides you with the latest tools and news about web design, web development, website maintenance, digital marketing, SEO, ecommerce, and cryptocurrency as well providing link building and backlink services through our dedicated blog!"
        let keywords = ["Pyxel Development Blog", "Website Backlink Service", "Website Link Building Service"]
        let location = "/blog"
        let isBlogPage = true
        return (
            <div>
                <Layout>
                    <Seo title={title} description={description} keywords={keywords} location={location} isBlogPage={isBlogPage} type="BlogPosting" />
                    <div className="bg-pyxelGray">
                        <div className="bg-white border border-gray-200 shadow-lg pt-breadcrumbs">
                            <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/blog', label: 'Blog' },
                                ]} />
                            </nav>
                        </div>
                        <div className="text-black font-bold pt-5 text-center lg:pt-12 px-5 lg:px-10">
                            <h1 className="text-4xl md:text-6xl xl:text-6xl uppercase mt-5 font-pixeboy mx-auto">
                                Pyxel Development Blog
                            </h1>
                            <p className="py-2 text-sm md:text-lg max-w-xl mx-auto">Find the latest news, insights, and resources for all things web design, ecommerce, online marketing, and much more </p>
                        </div>

                        <div className="container mx-auto py-7 lg:py-8 px-10 pb-9 lg:pb-12">
                            <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                                {posts && posts.map(({ node: post }) => {
                                    const title = post.title || post.slug
                                    const image = getImage(post.image)
                                    let blogCardTitle = post.category + " Blog Post"
                                    return (
                                        <div className="flex flex-col mx-auto border rounded-lg shadow-xl mb-3 xl:mb-12 bg-white" key={post.slug}>
                                            <article>
                                                <div className="max-w-md mx-auto hover:opacity-80 rounded-lg">
                                                    <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                        <GatsbyImage className="h-56 rounded-t-lg z-0" src="" image={image} title={post.image.title} alt={post.image.description} />
                                                    </Link>
                                                    <div className="px-5 py-5">
                                                        <p className="font-medium text-gray-600 uppercase">{post.category}</p>
                                                        <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                            <h3 className="pt-3 font-bold text-xl">{title}</h3>
                                                            <p className="py-3">{post.description}</p>
                                                        </Link>
                                                        <p>{post.author}</p>
                                                        <p className="pb-2">{post.date}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        )
    }
}
export default BlogPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulBlog(sort: { fields: date, order: DESC }) {
        edges{
            node{
                title
                category
                slug
                author
                body {
                    body
                }
                description
                date(formatString: "MMMM Do, YYYY")
                image {
                   gatsbyImageData(
                       width: 600
                       placeholder: NONE
                       formats: [AUTO, WEBP, AVIF]
                   )
                   title
                   description
                }
            }
        }
    }
}
`